import {
  ComponentTypeExtension,
  Summary,
  Subtype,
  simpleMeasurements,
  simpleSummaries,
  unitMeasurementSummaries,
  AreaChartData,
  GraphFilters,
  simpleAreaChartData,
  LineChartData,
  simpleLineChartData
} from "pbHelpers/ComponentType";
import PressureDarkIcon from "assets/components/pressureDark.png";
import PressureLightIcon from "assets/components/pressureLight.png";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";
import { convertedUnitMeasurement } from "models/UnitMeasurement";
import { pond } from "protobuf-ts/pond";

export function Pressure(subtype: number = 0): ComponentTypeExtension {
  let pressure = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PRESSURE,
    quack.ComponentType.COMPONENT_TYPE_PRESSURE,
    subtype
  );
  let addressTypes = [quack.AddressType.ADDRESS_TYPE_I2C];
  if (subtype === quack.PressureSubtype.PRESSURE_SUBTYPE_FROG) {
    addressTypes = [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY];
  }
  return {
    type: quack.ComponentType.COMPONENT_TYPE_PRESSURE,
    subtypes: [
      {
        key: quack.PressureSubtype.PRESSURE_SUBTYPE_NONE,
        value: "PRESSURE_SUBTYPE_NONE",
        friendlyName: "Absolute Pressure"
      } as Subtype,
      {
        key: quack.PressureSubtype.PRESSURE_SUBTYPE_GAUGE,
        value: "PRESSURE_SUBTYPE_GAUGE",
        friendlyName: "Pressure Gauge"
      } as Subtype,
      {
        key: quack.PressureSubtype.PRESSURE_SUBTYPE_FROG,
        value: "PRESSURE_SUBTYPE_FROG",
        friendlyName: "Pressure (Frog)"
      } as Subtype
    ],
    friendlyName: "Pressure",
    description: "Measures the atmospheric pressure or absolute pressure",
    isController: false,
    isSource: true,
    isCalibratable: true,
    hasFan: true,
    addressTypes: addressTypes,
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(pressure),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, pressure);
    },
    unitMeasurementSummary: (
      measurements: convertedUnitMeasurement,
      excludedNodes?: number[]
    ): Summary[] => {
      return unitMeasurementSummaries(
        measurements,
        quack.ComponentType.COMPONENT_TYPE_PRESSURE,
        subtype,
        excludedNodes
      );
    },
    areaChartData: (
      measurement: pond.UnitMeasurementsForComponent,
      smoothingAverages?: number,
      filters?: GraphFilters
    ): AreaChartData => {
      return simpleAreaChartData(measurement, smoothingAverages, filters);
    },
    lineChartData: (
      measurement: pond.UnitMeasurementsForComponent,
      smoothingAverages?: number,
      filters?: GraphFilters
    ): LineChartData => {
      return simpleLineChartData(
        quack.ComponentType.COMPONENT_TYPE_PRESSURE,
        measurement,
        smoothingAverages,
        filters
      );
    },
    minMeasurementPeriodMs: 1000,
    icon: (theme?: "light" | "dark"): string | undefined => {
      return theme === "light" ? PressureDarkIcon : PressureLightIcon;
    }
  };
}
